// ./controllers/date_input_controller.js
import "pikaday/css/pikaday.css";
import "../../css/vendor/pikaday.css";

import jQuery from "jquery";
import Pikaday from "pikaday";
import moment from "moment";
// import locale file(s)
import "moment/locale/es";
import "moment/locale/fr";

const SELECTOR = '[data-controller="date-input"]';
const DISPLAY_FORMAT = "MM/DD/YYYY";
jQuery((jQuery) => {
	// Set locale from the body, and update the Pikaday language.
	moment.locale(jQuery("body").data("locale") || "en");
	let i18n = {
		months: moment.months(),
		weekdays: moment.weekdays(),
		weekdaysShort: moment.weekdaysShort(),
	};

	jQuery(SELECTOR).map((i, obj) => {
		let $picker = jQuery(obj);
		let data = $picker.data();
		// Make a hidden input for the server-friendly date format.
		let $rawInput = jQuery("<input>");
		$rawInput.attr("type", "hidden");
		$rawInput.attr("name", $picker.attr("name"));
		$picker.closest("form").append($rawInput);

		// Make the picker not submit itself.
		$picker.attr("name", "");
		// Setup the data object for Pikaday.  For some reason, if I pass these as
		// a JSON object to Pikaday(); the date does not parse on initial page load.
		data["theme"] = "pm--date-input-modal";
		data["field"] = obj;
		data["i18n"] = i18n;
		//data["minDate"] = moment($picker.data("minDate")).toDate();
		//data["maxDate"] = moment($picker.data("maxDate")).toDate();
		//data["defaultDate"] = moment($picker.val()).toDate();
		data["minDate"] = moment($picker.data("minDate"));
		data["maxDate"] = moment($picker.data("maxDate"));
		data["defaultDate"] = moment($picker.val());
		data["setDefaultDate"] = true;
		data["onSelect"] = function () {
			$rawInput.val(this.getMoment().format("YYYYMMDD"));
		};
		data["format"] = DISPLAY_FORMAT;
		new Pikaday(data);
	});
});
