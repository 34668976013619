/******************************
 * Default pack for generic bootstrap template.
 * Smallest possible package, please!
 ******************************/
import "./js/global.js";
import "./css/progressive.scss";


// Space bar is using as enter key function
jQuery(function($) {
   	
    $('a').on('keypress', function(e) {
        if(e.keyCode == 32) {
            this.click();
        }
    });
   
    $('select').on('change', function() {
        this.focus();
    });

    $('#linkcompare-all-coverage').bind("keydown", function(e) {
         if (e.keyCode == 32) { 
          $("#linkcompare-all-coverage").click();        
    }
  });

    $(function () {
        $('body').on('keydown', '.pm--compare_all_coverage', function (e) {
            if (e.which == 32) {
                $(this).trigger("click")
                e.preventDefault();
            }
        });
    });

    $(function () {
        $('body').on('keydown', '.pm--qr-tab > a', function (e) {
            if (e.which == 32) {
                $(this).trigger("click");
                e.preventDefault();
            }
        });
    });

 
   
});

