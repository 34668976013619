// ./controllers/vehicle_controller.js
import jQuery from "jquery";

const ATTR = "data-pm-toggle";
const SELECTOR = `[${ATTR}]`;

jQuery((jQuery) => {
	jQuery(SELECTOR).map((i, obj) => {
		let $obj = jQuery(obj);
		let target = $obj.data("pm-toggle");
		let $target = jQuery(target);
		$obj.on("click", (e, t) => {
			$target.toggleClass("pm--visible");
			$target.toggleClass("pm--hidden");
		});
	});
});
