// ./controllers/auto_open_modal_controller.js

import jQuery from "jquery";

// Get the current hash and see if there is a modal with that hash as
// an id, and show if found.
jQuery((jQuery) => {
	let current_hash = window.location.hash.substr(1);
	if (current_hash != "") {
		jQuery(".pm--modal#" + current_hash).modal("show");
	}
});
