// ./controllers/vehicle_controller.js
import jQuery from "jquery";

const ATTR = "data-pm-auto-results-package";
const SELECTOR = `[${ATTR}]`;

document.bind_pm_auto_results_package = () => {
	jQuery(SELECTOR).map((i, obj) => {
		let $obj = jQuery(obj);
		let target = $obj.data("pm-auto-results-package");
		let $target = jQuery(target);
		$obj.on("click", (e, t) => {
			// Toggle visibility of all other packages.
			jQuery("div[data-pm-auto-results-package]").removeClass("pm--in");
			// Toggle visibility of selected package.
			jQuery('div[data-pm-auto-results-package="' + target + '"]').addClass(
				"pm--in"
			);
			// Toggle pm--btn-success on the selected package.
			jQuery("button[data-pm-auto-results-package]")
				.removeClass("pm--btn-success")
				.addClass("pm--btn-default");
			jQuery('button[data-pm-auto-results-package="' + target + '"]')
				.addClass("pm--btn-success")
				.removeClass("pm--btn-default");
		});
	});
};

jQuery((jQuery) => {
	// This is here so that bind_* can be called when the AJAX
	// results are loaded in.  For some reason the jQuery.trigger()
	// function is simply not firing.  No clue why.
	document.bind_pm_auto_results_package();
});
