// ./controllers/vehicle_controller.js
import jQuery from "jquery";

const ATTR = "data-pm-stop-form-unless";
const SELECTOR = `[${ATTR}]`;

document.bind_pm_stop_form_unless = () => {
	jQuery(SELECTOR).map((i, obj) => {
		let $obj = jQuery(obj);
		// let target = $obj.data("pm-stop-form-unless");
		let $form = $obj.closest("form");
		// let $target = jQuery(target);
		$obj.on("click", (e) => {
			let $el = jQuery(
				'input[name="' + $obj.data("pm-stop-form-unless") + '"]'
			);
			if (!$el.filter(":visible").is(":checked")) {
				e.preventDefault();
				alert($obj.data("pm-stop-form-with"));
			}
		});
	});
};

jQuery((jQuery) => {
	document.bind_pm_stop_form_unless();
});
