// ./controllers/vehicle_controller.js
import jQuery from "jquery";

const ATTR = "data-pm-highlights-label";
const SELECTOR = `[${ATTR}]`;

document.bind_pm_highlights_label = () => {
	jQuery(SELECTOR).map((i, obj) => {
		let $obj = jQuery(obj);
		let $target = jQuery('label[for="' + $obj.attr("id") + '"]');
		$obj.on("changed load click", (e, t) => {
			if ($obj.data("pm-unique-highlight-with")) {
				jQuery($obj.data("pm-unique-highlight-with")).removeClass(
					"pm--highlight"
				);
			}
			if ($obj.is(":checked")) {
				$target.addClass("pm--highlight");
			} else {
				$target.removeClass("pm--highlight");
			}
		});
	});
};

jQuery((jQuery) => {
	document.bind_pm_highlights_label();
});
